import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { Observable } from 'rxjs';
import { enviorment } from '@core/settings/enviorment';
import { UsuarioService } from './usuario.service';
import { User } from '@core/models/user';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class GestionUsuariosService {

  constructor(public http: HttpClient, public _usuarioService: UsuarioService) { }

  getUsersActive(idCompany): Observable<any> {
    let token = this.getToken();
    return this.http.get(enviorment + 'ManagementUser/' + idCompany, { headers: token});
  }

  deleteUser(idUser): Observable<any> {
    let token = this.getToken();
    return this.http.get(enviorment + 'ManagementUser/DeleteUser/' + idUser, { headers: token});
  }

  getCivilState(): Observable<any> {
    let token = this.getToken();
    return this.http.get(enviorment + 'ManagementUser/GetCivilState', { headers: token});
  }

  getProfiles(): Observable<any> {
    let token = this.getToken();
    return this.http.get(enviorment + 'ManagementUser/GetProfiles', { headers: token});
  }

  postUser(usuario: User): Observable<any> {
    let token = this.getToken();
    return this.http.post(enviorment + 'ManagementUser', usuario, { headers: token});
  }
  
  getUser(idUser): Observable<any> {
    let token = this.getToken();
    return this.http.get(enviorment + 'ManagementUser/GetUser/' + idUser, { headers: token});
  }

  updateUser(usuario: User): Observable<any> {
    let token = this.getToken();
    return this.http.put(enviorment + 'ManagementUser/'+usuario.id, usuario, { headers: token});
  }

  getToken() {
    let var12 = JSON.parse(localStorage.getItem('token'));
    let tokenHeader1 = new HttpHeaders({'Authorization': 'Bearer '+ var12});
    return tokenHeader1;
  }
}
