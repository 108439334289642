import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { Observable,forkJoin } from 'rxjs';
import { enviorment } from '@core/settings/enviorment';
import { UsuarioService } from './usuario.service';
import { ComercialPlan } from '@core/models/ComercialPlan';
import { CompaniePlan } from '@core/models/CompaniePlan';
import { getLocaleDateTimeFormat } from '@angular/common';

@Injectable({
  providedIn: 'root'
})


export class PlanesService {
    constructor(public http: HttpClient, public _usuarioService: UsuarioService) { }

    getTipoPlanes(): Observable<any> {
        let token = this.getToken();
        return this.http.get(enviorment+'Plans/GetPlanesType/', { headers: token});
      }
      getToken() {
        let var12 = JSON.parse(localStorage.getItem('token'));
        let tokenHeader1 = new HttpHeaders({'Authorization': 'Bearer '+ var12});
        return tokenHeader1;
      }
      getTipoSms(idPlan): Observable<any> {
        let token = this.getToken();
        return this.http.get(enviorment+'Plans/GetTipoMensajeSegunPlan/'+idPlan, { headers: token});
      }

      postComercialPlan(ComercialPlan: ComercialPlan): Observable<any> {
        //campaign.userId = this._usuarioService.getUsuario().id;
        //campaign.CompanyId = this._usuarioService.getUsuario().companyId;
        //const token = this.getToken();
    
        return this.http.post(enviorment + 'Plans', ComercialPlan/*, { headers: token}*/ );
      }

      getPlans(): Observable<any> {
        let token = this.getToken();
        return this.http.get(enviorment+'Plans/GetPlans/', { headers: token});
      }

      deletePlan(id): Observable<any> {
        let token = this.getToken();
        return this.http.delete(enviorment+'Plans/'+id, { headers: token});
      }

      
      postCompaniePlan(CompaniePlan: CompaniePlan): Observable<any> {
        //campaign.userId = this._usuarioService.getUsuario().id;
        //campaign.CompanyId = this._usuarioService.getUsuario().companyId;
        //const token = this.getToken();
    
        return this.http.post(enviorment + 'Plans/PostCompaniePlan', CompaniePlan/*, { headers: token}*/ );
      }

      getCompaniePlans(idEmp): Observable<any> {
        let token = this.getToken();
        return this.http.get(enviorment+'Plans/getCompaniePlans/'+idEmp, { headers: token});
      }

      deleteAsocitedPlan(id): Observable<any> {
        let token = this.getToken();
        return this.http.delete(enviorment+'Plans/deleteAsocitedPlan/'+id, { headers: token});
      }

      
      anyPlan(idEmpresa): Observable<any> {
        let token = this.getToken();
        return this.http.get(enviorment+'Plans/anyPlan/'+idEmpresa, { headers: token});
      }

      // editPlan(ComercialPlan: ComercialPlan): Observable<any> {
      //   //campaign.userId = this._usuarioService.getUsuario().id;
      //   //campaign.CompanyId = this._usuarioService.getUsuario().companyId;
      //   //const token = this.getToken();
    
      //   return this.http.post(enviorment + 'Plans', ComercialPlan/*, { headers: token}*/ );
      // }
      editPlan(ComercialPlan, id): Observable<any> {
        const token = this.getToken();
        return this.http.put(enviorment + 'Plans/' + id, ComercialPlan,  { headers: token} );
      }

      public getCompaniePlans2(): Observable<any> {
        const token = this.getToken();
        const idCompany = this._usuarioService.getUsuario().companyId;
        return this.http.get(enviorment + 'Plans/getCompaniePlans/' + idCompany, { headers: token});
       // const response2 = this.http.get(enviorment + 'Plans/CantMsjEnviadosMesPlan/' + idCompany, { headers: token});
        //const response3 = this.http.get(enviorment + 'Campaign/CantMsjEnviadosMes/' + idCompany, { headers: token});
        //return forkJoin([response1]);
      }

      public requestDataFromMultipleSources(CompaniePlanGrid): Observable<any> {
        const token = this.getToken();
        const idCompany = this._usuarioService.getUsuario().companyId;
        var dateCycle = CompaniePlanGrid.dateCycle;       
        const companyComercialPlanId= CompaniePlanGrid.id;
        const esPLan = CompaniePlanGrid.comercialPlanTypeName == "Mensual"
        if(dateCycle == null && esPLan) return;
        if(!esPLan) dateCycle = CompaniePlanGrid.dateStart;
        const dateExpiration:Date = CompaniePlanGrid.dateExpiration == null? "0001-01-01T00:00:00":CompaniePlanGrid.dateExpiration;
        //let dates:Date;
        //return this.http.get(enviorment + 'Plans/getCompaniePlans/' + idCompany, { headers: token});
        return this.http.get(enviorment + 'Plans/CantMsjEnviadosMesPlan/' + idCompany+'/'+dateCycle+'/'+companyComercialPlanId+'/'+esPLan+'/'+dateExpiration, { headers: token});
        //const response2 = this.http.get(enviorment + 'Campaign/CantMsjEnviadosMes/' + idCompany, { headers: token});
        //return forkJoin([response1]);
      }


}