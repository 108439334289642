export class ComercialPlan{
id:number;
name:string;
comercialPlanTypeId :number;
tipoMensajeId:number;
smsPrice:number;
quantity:number;
dateCreated:Date;
dateEnd?:Date;
expirationDays?:number;
tipoMsj:string;
comercialPlanTypeName:string;

}